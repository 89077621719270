import { useFormEditorStore } from "../stores/formEditorStore";


const search = (rules, characteristic) => {
    if (rules?.length) {
        const rule = rules.find((value) => value.feature == characteristic);
        return rule;
    }
}

const evaluate = (rule) => {
    const formEditorStore = useFormEditorStore();
    const value = formEditorStore.getWidgetValueByName(rule.subject);
    let result = false;
    switch (rule.operator) {
        case 'equal': {
            result = value == rule.value;
            break;
        }
        case 'not-equal': {
            result = value != rule.value;
            break;
        }
        case 'greater-than': {
            result = value > rule.value;
            break;
        }
        case 'greater-or-equal-than': {
            result = value >= rule.value;
            break;
        }
        case 'less-than': {
            result = value < rule.value;
            break;
        }
        case 'less-or-equal-than': {
            result = value <= rule.value;
            break;
        }
        case 'contains': {
            result = value && value.includes(rule.value);
            break;
        }
        case 'is-checked': {
            result = (value == true);
            break;
        }
        case 'is-not-checked': {
            result = (!value || (value == false));
            break;
        }
    }
    return result;
};

export default {
    isVisible: (widget) => {
        const rule = search(widget.instanceProperties.rules, 'visibility');
        if (rule) {
            return evaluate(rule);
        }
        return true;
    },
    isDisabled: (widget) => {
        const rule = search(widget.instanceProperties.rules, 'disabled');
        if (rule) {
            return evaluate(rule);
        }
        return false;
    },
    isReadonly: (widget) => {
        const rule = search(widget.instanceProperties.rules, 'readonly');
        if (rule) {
            return evaluate(rule);
        }
        return false;
    },
    isRequired: (widget) => {
        const rule = search(widget.instanceProperties.rules, 'required');
        if (rule) {
            return evaluate(rule);
        }
        return false;
    },


};
