import { ref, watch } from "vue";
import { defineStore } from "pinia";

import { v4 as uuidv4 } from 'uuid';
import _, { isArray } from 'lodash';

import templateService from "../../../services/template";
import { reportWidgetsClasses } from '../data/report-widgets.js';

import { useFormEditorStore } from '../stores/formEditorStore.js';

export const useTemplateStore = defineStore('template-store', () => {
    // template caricato da server
    const template = ref([]);
    const templateId = ref(null);

    // indice dei campi delle form per sezione (editor)
    const fieldsBySection = ref({});
    // elenco dei template item che costituiscono il template (editor)
    const templateDef = ref({});
    // template items selezionati (editor)
    const templateSelectedTemplateItems = ref([]);
    //
    const suspendedNavDestination = ref(null);
    //modifiche non salvate
    const showTemplateNotSavedModal = ref(false);
    // template editor parameters
    const pageState = ref({
        zoom: 1,
        translateX: 0,
        translateY: 0,
    });
    // multi-template
    const pages = ref({
        current: 0,
        total: 1,
        templates: [templateDef.value]
    });
    // for controlling page and widget panning 
    const disableAllPans = ref(false);

    // parametri search
    const sort = ref([]);
    const filters = ref([]);

    // se il template e' stato modificato (non usato per i templates, rimane sempre a true)
    const wasModified = ref(false);

    // 
    const formEditorStore = useFormEditorStore();

    let unwatchDef = [];
    function setWatchDef() {
        console.log("SET WATCH DEF");
        if (unwatchDef) {
            console.log("UNWATCHING");
            unwatchDef.forEach((unwatch) => {
                unwatch();
            });
            unwatchDef = [];
        }
        console.log("REGISTER");
        Object.keys(templateDef.value).forEach((key) => {
            // templateDef.value[key].layout.forEach((l) => {
            unwatchDef.push(watch(
                // () => (formDefIndex.value[key].instanceProperties),
                templateDef.value[key].instanceProperties,
                (newValue, oldValue) => {
                    console.log("EXEC WATCH", key);
                    wasModified.value = true;
                    console.log("OLD ", oldValue);
                    console.log("NEW", newValue);
                }, { deep: true }
            ));
            // });
        });
        console.log("WATCH FOR LENGTH");
        // if (!_.isEmpty(templateDef.value)) {
        unwatchDef.push(watch(
            () => (Object.keys(templateDef.value).length),
            (newValue, oldValue) => {
                console.log("EXEC WATCH LENGTH");
                wasModified.value = true;
                console.log("OLD LENGTH", oldValue);
                console.log("NEW LENGTH", newValue);
            }, { deep: false }
        ));
        // }
        console.log("SET WATCH DEF END");
    }

    function loadTemplateDefinition(template_id) {
        return templateService.load(template_id)
            .then((response) => {
                console.log("LOAD TEMPLATE DEF: ", response);
                //template.value = null;
                if (response.data.template == null) {
                    template.value = [];
                    templateId.value = null;
                    templateDef.value = {};
                } else {
                    template.value = response.data.template;
                    templateId.value = response.data.template.id;
                    // console.log('template.value.layout', JSON.parse(template.value.layout));
                    // if (JSON.parse(template.value.layout) !== '{}') {
                    //     templateDef.value = JSON.parse(response.data.template.layout);
                    //     console.log('tenplateDef VALUE', templateDef.value);
                    // } else {
                    //     templateDef.value = {};
                    // }
                    pages.value.templates = template.value.layout.map((value) => {
                        if (isArray(value) && value.length == 0) {
                            return {};
                        }
                        return value;
                    });
                    pages.value.current = 0;
                    pages.value.total = pages.value.templates.length;
                    templateDef.value = pages.value.templates[pages.value.current];
                    wasModified.value = false;
                    setWatchDef();

                    const r = template.value.type_analysis.sections.reduce((carry, section) => {
                        if (section.form_definitions_latest_version != null) {
                            formEditorStore.rebuildIndex(section.form_definitions_latest_version.fields);
                            console.log('OBJECT VALUES', Object.values(formEditorStore.formDefIndex));
                            carry[section.id] = {
                                section,
                                fields: Object.values(formEditorStore.formDefIndex).reduce((carry, widgetDef) => {
                                    if (widgetDef.classProperties.type != 'form-layout') {
                                        const templateItem = templateItemInstance('data')
                                        templateItem.instanceProperties.field = widgetDef;
                                        templateItem.instanceProperties.section_id = section.id;
                                        carry[templateItem.instanceProperties.id] = templateItem;
                                    }
                                    return carry;
                                }, {}),
                            };
                            // Correzione vecchi record senza section_id
                            Object.values(templateDef.value).forEach((value) => {
                                console.log('VALUE', value);
                                if (value.classProperties.code == 'data' && formEditorStore.formDefIndex[value.instanceProperties.field.instanceProperties.id]) {
                                    if (!Object.keys(value.instanceProperties).includes('section_id')) {
                                        value.instanceProperties.section_id = section.id;
                                        console.log('SET SECTION');
                                    }
                                    if (!value.instanceProperties.alignment) {
                                        value.instanceProperties.alignment = 'left';
                                        console.log('SET ALIGNMENT');
                                    }
                                }
                            });
                        }
                        return carry;
                    }, {});
                    console.log('FIELDS BY SECTION', r);
                    console.log('TEMPLATE DEF', templateDef.value);
                    fieldsBySection.value = r;
                }

                return success(null);
            })
            .catch((response) => {
                console.log("LOAD TEMPLATE DEF ERR: ", response);
                return failure('Si è verificato un errore durante il caricamento del template');
            });
    }

    function propertiesEditorFromWidgetId(widgetId) {
        const w = templateDef.value[widgetId];
        if (w) {
            const wd = templateItemDefFromClassName(w.classProperties.name);
            return wd.propertiesEditor;
        }
        return w;
    }

    function templateItemDefFromClassName(widgetName) {
        const w = reportWidgetsClasses().find((widgetDef) => {
            return widgetDef.classProperties.name == widgetName;
        });
        return w;
    }

    function templateItemInstance(templateItemDef) {
        let wd = templateItemDef;
        if (typeof templateItemDef == 'string') {
            wd = templateItemDefFromClassName(templateItemDef);
        }
        const templateItem = _.cloneDeep(_.pick(wd, ['classProperties', 'instanceProperties', 'runtimeProperties']));
        templateItem.instanceProperties.id = uuidv4();
        return templateItem;
    }


    function appendTemplateItem(templateItemDef, offsetX, offsetY) {
        const templateItem = templateItemInstance(templateItemDef);
        templateItem.instanceProperties.x = offsetX;
        templateItem.instanceProperties.y = offsetY;
        templateDef.value[templateItem.instanceProperties.id] = (templateItem);

        clearSelection();
    }

    function removeTemplateItem(templateItemId) {
        delete templateDef.value[templateItemId];

        clearSelection();
    }

    function toggleSelection(id) {
        const index = templateSelectedTemplateItems.value.indexOf(id);
        if (index == -1) {
            templateSelectedTemplateItems.value.push(id);
        } else {
            templateSelectedTemplateItems.value.splice(index, 1);
        }
        templateDef.value[id].runtimeProperties.selected = isSelected(id);
        // templateDef.value[id].runtimeProperties.selectedState = isSelected(id);

    }
    function clearSelection() {
        templateSelectedTemplateItems.value = [];
        _.forEach(templateDef.value, (templateItem) => {
            templateItem.runtimeProperties.selected = false;
        });
    }
    function isSelected(id) {
        const r = (templateSelectedTemplateItems.value.indexOf(id) != -1);
        return r;
    }
    function isSingleSelected(id) {
        const r = (templateSelectedTemplateItems.value.indexOf(id) != -1) && (templateSelectedTemplateItems.value.length);
        return r;
    }

    function addPage() {
        clearSelection();
        pages.value.templates.push({});
        pages.value.total = pages.value.templates.length;
        pages.value.current = pages.value.total - 1;
        templateDef.value = pages.value.templates[pages.value.current];
        wasModified.value = true;
        setWatchDef();
    }

    function prevPage() {
        clearSelection();
        if (pages.value.current > 0) {
            pages.value.current -= 1;
        }
        templateDef.value = pages.value.templates[pages.value.current];
        setWatchDef();
    }

    function nextPage() {
        clearSelection();
        if (pages.value.current < pages.value.total - 1) {
            pages.value.current += 1;
        }
        templateDef.value = pages.value.templates[pages.value.current];
        setWatchDef();
    }

    function componentFromTemplateItemClassName(name) {
        const w = reportWidgetsClasses().find((widgetDef) => {
            return widgetDef.classProperties.name == name;
        });
        return w.component;

    }



    function createTemplate(model) {
        return templateService.create(model);
    }
    function updateTemplateLayout() {
        wasModified.value = false;
        return templateService.updateLayout(template.value.id, pages.value.templates);
    }
    async function updateTemplateBackgroundImage(backgroundImage) {
        const updatedTemplate = await templateService.updateBackgroundImage(template.value.id, backgroundImage);
        if (updatedTemplate) {
            template.value = updatedTemplate;
        }
    }
    async function fetchTemplates(filter, sort, pagination, format) {
        const s = await templateService.search(
            filter.filter(item => item.value),
            sort.filter(item => item.dir),
            pagination,
            format
        );
        console.log("fetchTemplates: ", s);
        return s;
    }
    function deleteTemplate(id) {
        return templateService.delete(id);
    }
    function success(_data) {
        return {
            status: 'success',
            data: _data
        }
    }
    function failure(_error) {
        return {
            status: 'error',
            error: _error
        }
    }


    return {
        template,
        templateDef,
        templateSelectedTemplateItems,

        suspendedNavDestination,
        showTemplateNotSavedModal,

        fieldsBySection,

        pages,
        pageState,
        addPage,
        prevPage,
        nextPage,

        disableAllPans,

        toggleSelection,
        clearSelection,
        isSelected,
        isSingleSelected,

        sort,
        filters,

        appendTemplateItem,
        removeTemplateItem,

        componentFromTemplateItemClassName,
        propertiesEditorFromWidgetId,

        createTemplate,
        updateTemplateLayout,
        updateTemplateBackgroundImage,
        loadTemplateDefinition,
        fetchTemplates,
        deleteTemplate,

        wasModified
    }
});
