import { reactive, ref, watch } from 'vue';
import { defineStore } from 'pinia';

import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import formEditorService from '../services/FormEditorService.js';
import rulesService from '../services/RulesService.js';

import { formWidgetsClasses, allWidgetsClasses } from '../data/form-widgets.js';

import { useProcessingStore } from './processingStore.js';

export const useFormEditorStore = defineStore('form-editor-store', () => {
    const processingStore = useProcessingStore();

    const formDef = ref(null);
    const formDefIndex = ref({});
    const formDefSelectedWidgets = ref([]);

    const definitionId = ref(null);

    const widgetCounters = ref({});
    // const isModified = ref(false);
    const wasModified = ref(false);

    const suspendedNavDestination = ref(null);
    const showFormNotSavedModal = ref(false);

    // INIT 
    function initNomenclature() {
        if (Object.keys(formDefIndex.value).length == 0) {
            widgetCounters.value = formWidgetsClasses().reduce((carry, value) => {
                carry[value.classProperties.name] = 0;
                return carry;
            }, widgetCounters.value);
            widgetCounters.value['container'] = 0;
        } else {
            // TODO: ricerca del massimo contatore dei nomi nelle istanze (se il nome corrisponde al patter code-number, estraggo number e se maggiore dell'ultimo contatore trovato per quel code allora aggiorno il contatatore al nuovo valore)
            widgetCounters.value = formWidgetsClasses().reduce((carry, widgetClass) => {
                let maxValue = 0;
                Object.keys(formDefIndex.value).forEach((widgetId) => {
                    const regexp = new RegExp('^' + widgetClass.classProperties.code + '-' + '([0-9]+)$', 'g');
                    const matches = [...formDefIndex.value[widgetId].instanceProperties.name.matchAll(regexp)];
                    if (matches.length && parseInt(matches[0][1]) > maxValue) {
                        maxValue = parseInt(matches[0][1]);
                    }
                });
                carry[widgetClass.classProperties.name] = maxValue;
                return carry;
            }, {});
        }
    }
    // UTILS
    function componentFromWidgetClassName(widgetName) {
        const w = allWidgetsClasses().find((widgetDef) => {
            return widgetDef.classProperties.name == widgetName;
        });
        return w.component;
    }
    function widgetDefFromClassName(widgetName) {
        const w = allWidgetsClasses().find((widgetDef) => {
            return widgetDef.classProperties.name == widgetName;
        });
        return w;
    }
    function propertiesEditorFromWidgetId(widgetId) {
        const w = formDefIndex.value[widgetId];
        if (w) {
            const wd = widgetDefFromClassName(w.classProperties.name);
            return wd.propertiesEditor;
        }
        return w;
    }
    function plainWidgetName(widget) {
        return widget.classProperties.code + '-' + widgetCounters.value[widget.classProperties.name];
    }
    function buildPlainWidgetName(widget) {
        widgetCounters.value[widget.classProperties.name]++;
        return plainWidgetName(widget);
    }
    function isUniqueWidgetName(id, name) {
        return !Object.keys(formDefIndex.value).find((key) => {
            if (id != key)
                if (formDefIndex.value[key].instanceProperties.name == name) {
                    return true;
                }
            return false;
        });
    }
    // FORM TREE MANAGEMENT
    function widgetInstance(widgetDef) {
        let wd = widgetDef;
        if (typeof widgetDef == 'string') {
            wd = widgetDefFromClassName(widgetDef);
        }
        const widget = _.cloneDeep(_.pick(wd, ['classProperties', 'instanceProperties', 'runtimeProperties', 'validationProperties']));
        widget.instanceProperties.id = uuidv4();
        return widget;
    }
    function widgetPush(widget) {
        widget.instanceProperties.name = buildPlainWidgetName(widget);
        widget.instanceProperties.pathname = [];
        widget.instanceProperties.pathname.push(widget.instanceProperties.name);

        formDefIndex.value[widget.instanceProperties.id] = widget;
    }
    function addRootWidget() {
        // crea nuova istanza di root-layout
        const rootWidget = widgetInstance('container');
        console.log("ROOT CHECK: ", (rootWidget.classProperties === formWidgetsClasses()[0].classProperties) ? "true" : "false");
        // inizializza il root-layout
        rootWidget.instanceProperties.id = "0";
        rootWidget.instanceProperties.name = buildPlainWidgetName(rootWidget);
        rootWidget.instanceProperties.pathname.push(rootWidget.instanceProperties.name);

        // aggiunge il root widget all'indice dei componenti
        formDefIndex.value[rootWidget.instanceProperties.id] = rootWidget;
        // aggiunge alla struttura della form il reference al root widget
        formDef.value = formDefIndex.value[rootWidget.instanceProperties.id];

        // isModified.value = true;

        return rootWidget;
    }
    function appendWidget(widgetContainer, widgetDef) {
        const widget = widgetInstance(widgetDef);
        widgetPush(widget);

        widgetContainer.instanceProperties.blocks.push({ inlines: [] });
        widgetContainer.instanceProperties.blocks[widgetContainer.instanceProperties.blocks.length - 1].inlines.push(widget);

        clearSelection();
        // isModified.value = true;
        return widgetContainer;
    }
    function insertWidgetBefore(widgetContainer, widgetDef, blockIndex) {
        const widget = widgetInstance(widgetDef);
        widgetPush(widget);

        widgetContainer.instanceProperties.blocks.splice(blockIndex, 0, { inlines: [] });
        widgetContainer.instanceProperties.blocks[blockIndex].inlines.push(widget);

        clearSelection();
        // isModified.value = true;
        return widgetContainer;
    }
    function appendWidgetInline(widgetContainer, widgetDef, blockIndex) {
        console.log("APPEND INLINE", blockIndex);
        const widget = widgetInstance(widgetDef);
        widgetPush(widget);

        widgetContainer.instanceProperties.blocks[blockIndex].inlines.push(widget);

        clearSelection();
        // isModified.value = true;
        return widgetContainer;
    }
    function insertWidgetInlineBefore(widgetContainer, widgetDef, blockIndex, inlineIndex) {
        console.log("INSERT INLINE", blockIndex, inlineIndex);
        const widget = widgetInstance(widgetDef);
        widgetPush(widget);

        widgetContainer.instanceProperties.blocks[blockIndex].inlines.splice(inlineIndex, 0, widget);

        clearSelection();
        // isModified.value = true;
        return widgetContainer;
    }

    function recursiveRemoveWidget(widgetContainer) {
        for (let blockIndex = 0; blockIndex < widgetContainer.instanceProperties.blocks.length; blockIndex++) {
            for (let inlineIndex = 0; inlineIndex < widgetContainer.instanceProperties.blocks[blockIndex].inlines.length; inlineIndex++) {
                if (widgetContainer.instanceProperties.blocks[blockIndex].inlines[inlineIndex].classProperties.type == 'form-layout') {
                    recursiveRemoveWidget(widgetContainer.instanceProperties.blocks[blockIndex].inlines[inlineIndex]);
                } else {
                    const widgetId = widgetContainer.instanceProperties.blocks[blockIndex].inlines[inlineIndex].instanceProperties.id;
                    widgetContainer.instanceProperties.blocks[blockIndex].inlines.splice(inlineIndex, 1);
                    inlineIndex--;
                    delete formDefIndex.value[widgetId];
                }
            }
            if (widgetContainer.instanceProperties.blocks[blockIndex].inlines.length == 0) {
                widgetContainer.instanceProperties.blocks.splice(blockIndex, 1);
                blockIndex--;
            }
        }
        delete formDefIndex.value[widgetContainer.instanceProperties.id];
    }
    function removeWidget(widgetContainer, blockIndex, inlineIndex) {
        console.log("REMOVE WIDGET");
        const widgetId = widgetContainer.instanceProperties.blocks[blockIndex].inlines[inlineIndex].instanceProperties.id;

        if (isSelected(widgetId)) {
            formDefSelectedWidgets.value.splice(formDefSelectedWidgets.value.indexOf(widgetId), 1);
        }
        if (widgetContainer.instanceProperties.blocks[blockIndex].inlines[inlineIndex].classProperties.type == 'form-layout') {
            recursiveRemoveWidget(widgetContainer.instanceProperties.blocks[blockIndex].inlines[inlineIndex]);
        }
        widgetContainer.instanceProperties.blocks[blockIndex].inlines.splice(inlineIndex, 1);
        if (!widgetContainer.instanceProperties.blocks[blockIndex].inlines?.length) {
            widgetContainer.instanceProperties.blocks.splice(blockIndex, 1);
        }
        delete formDefIndex.value[widgetId];
        // isModified.value = true;
    }

    function recursiveCollectWidgetsFormDataLocal(formData, startWidget) {
        if (startWidget.classProperties.type == 'form-layout') {
            let inlineData = {};
            startWidget.instanceProperties.blocks.forEach((block) => {
                block.inlines.forEach((inline) => {
                    inlineData[inline.instanceProperties.name] = recursiveCollectWidgetsFormDataLocal(formData, inline);
                })
            });
            return inlineData;
        }
        if (startWidget.runtimeProperties.data instanceof File) {
            formData.set(startWidget.instanceProperties.name, startWidget.runtimeProperties.data);
            return '';
        }
        return startWidget.runtimeProperties.data;
    }
    function recursiveCollectWidgetsDataLocal(startWidget) {
        if (startWidget.classProperties.type == 'form-layout') {
            let inlineData = {};
            startWidget.instanceProperties.blocks.forEach((block) => {
                block.inlines.forEach((inline) => {
                    inlineData[inline.instanceProperties.name] = recursiveCollectWidgetsDataLocal(inline);
                })
            });
            return inlineData;
        }
        return startWidget.runtimeProperties.data;
    }
    function hasImagesOrFiles(index) {
        return !!Object.keys(index).find((key) => {
            return index[key].classProperties.valueType == 'data';
        })
    }
    function recursiveCollectWidgetsData(startWidget, indexWidget) {
        if (hasImagesOrFiles(indexWidget)) {
            const formData = new FormData();
            const jsonPart = recursiveCollectWidgetsFormDataLocal(formData, startWidget);
            formData.set("data", JSON.stringify(jsonPart));
            return formData;
        } else {
            return recursiveCollectWidgetsDataLocal(startWidget);
        }
    }
    function collectWidgetsData() {
        const data = formDef.value ? recursiveCollectWidgetsData(formDef.value, formDefIndex.value) : null;
        return data;
    }

    function recursiveAssignWidgetsData(startWidget, data) {
        if (startWidget.classProperties.type == 'form-layout') {
            startWidget.instanceProperties.blocks.forEach((block) => {
                block.inlines.forEach((inline) => {
                    // inline.runtimeProperties.data = data[inline.instanceProperties.name];
                    if (data)
                        recursiveAssignWidgetsData(inline, data[inline.instanceProperties.name]);
                })
            });
            return;
        }
        if (startWidget.classProperties.name == 'input-date') {
            data = new Date(data)
        }
        startWidget.runtimeProperties.data = data;
    }
    function assigntWidgetsData(data) {
        recursiveAssignWidgetsData(formDef.value, data);
    }

    function widgetInstanceProps(id) {
        return formDefIndex.value[id].instanceProperties;
    }
    function widgetRuntimeProps(id) {
        return formDefIndex.value[id].runtimeProperties;
    }

    // WIDGET SELECTION IN EDIT MODE
    function toggleSelection(id) {
        const index = formDefSelectedWidgets.value.indexOf(id);
        if (index == -1) {
            formDefSelectedWidgets.value.push(id);
        } else {
            formDefSelectedWidgets.value.splice(index, 1);
        }
        formDefIndex.value[id].runtimeProperties.selected = isSelected(id);
    }
    function clearSelection() {
        formDefSelectedWidgets.value = [];
        _.forEach(formDefIndex.value, (widget) => {
            widget.runtimeProperties.selected = false;
        });
    }
    function isSelected(id) {
        const r = (formDefSelectedWidgets.value.indexOf(id) != -1);
        return r;
    }

    // API INTERFACE
    function success(_data) {
        return {
            status: 'success',
            data: _data
        }
    }
    function failure(_error) {
        return {
            status: 'error',
            error: _error
        }
    }

    function rebuildIndexLocal(definition) {
        const localFormDefIndex = {};
        if (definition == null) {
            return localFormDefIndex;
        }
        if (definition.classProperties.type == 'form-layout') {
            localFormDefIndex[definition.instanceProperties.id] = definition;
            definition.instanceProperties.blocks.forEach((blockItem) => {
                blockItem.inlines.forEach((inlineItem) => {
                    if (inlineItem.classProperties.type == 'form-layout') {
                        rebuildIndex(inlineItem);
                    } else {
                        localFormDefIndex[inlineItem.instanceProperties.id] = inlineItem;
                    }
                })
            });
        } else {
            localFormDefIndex[definition.instanceProperties.id] = definition;
        }
        return localFormDefIndex;
    }

    function rebuildIndex(definition) {
        formDefIndex.value = rebuildIndexLocal(definition);
    }

    // function loadFormDefinitionLocal(_sectionId) {
    //     formEditorService.load(_sectionId)
    //         .then((response) => {
    //             const form = {
    //                 localFormDef: null,
    //                 localFormDefIndex: {}
    //             };
    //             if (response.data.definition != null) {
    //                 form.localFormDef = response.data.definition.fields;
    //                 form.localFormDefIndex = rebuildIndexLocal(form.localFormDef);
    //             }
    //             return form;
    //         })
    //         .catch((response) => {
    //             console.log("LOAD FORM DEF ERR: ", response);
    //             return false;
    //         });
    // }
    function loadFormDefinition(_sectionId, callback = null) {
        return formEditorService.load(_sectionId)
            .then((response) => {
                console.log("LOAD FORM DEF: ", response);
                formDef.value = null;
                formDefIndex.value = {};
                formDefSelectedWidgets.value = [];
                if (response.data.definition != null) {
                    formDef.value = response.data.definition.fields;
                    definitionId.value = response.data.definition.id;
                    rebuildIndex(formDef.value);
                }
                wasModified.value = false;
                setWatchDef();
                initNomenclature();

                if (callback) {
                    callback();
                }
                return success(null);
            })
            .catch((response) => {
                console.log("LOAD FORM DEF ERR: ", response);
                return failure('Si è verificato un errore durante il caricamento della form');
            });
    }
    function saveFormDefinition(sectionId) {
        const form = {};

        form.definition = {
            fields: formDef.value
        };
        if (definitionId.value) {
            form.definition.id = definitionId.value
        }
        clearSelection();
        return formEditorService.save(sectionId, form)
            .then((response) => {
                definitionId.value = response.data.definition.id;
                wasModified.value = false;
                return success(null)
            })
            .catch((response) => {
                console.log("SAVE FORM DEF: ", response);
                return failure('Si è verificato un errore durante il salvataggio');
            });
    }

    // salva i dati di tutte le form visualizzate da un  analista (che abbia o meno modificato qualcosa)
    function saveAllFormData() {
        console.log("SAVE ALL FORM DATA", cacheFormDef.value);
        const savePromises = Object.keys(cacheFormDef.value).reduce((carry, key) => {
            const materialezKey = JSON.parse(key);
            // null nel caso quella form non sia mai stata caricata nella sessione dell'analista

            if (materialezKey.stone != -1 && cacheFormDef.value[key].formDef != null) {
                const r = recursiveCollectWidgetsData(cacheFormDef.value[key].formDef, cacheFormDef.value[key].formDefIndex);
                if (r instanceof FormData) {
                    console.log("SAVE DATA FD", [...r]);
                } else {
                    console.log("SAVE DATA", r);
                }
                carry.push(saveFormData(materialezKey.stone, materialezKey.section, r));
            }
            return carry;
        }, []);
        return Promise.all(savePromises)
            .then((response) => {
                console.log("SAVE ALL OK RESP", response);
                wasModified.value = false; // probabilmente non serve, non ci sono watch sui dati
                return success(null)
            })
            .catch((response) => {
                console.log("SAVE ALL ERR RESP"), response;
                return failure('Si è verificato un errore durante il salvataggio');
            });
    }
    function saveFormData(stoneId, sectionId, data) {
        console.log("SAVE FORM DATA", stoneId, sectionId, data);
        return formEditorService.storeData(stoneId, sectionId, data);
    }
    function loadFormDataLocal(stoneId, sectionId) {
        console.log("LOAD FORM DATA", stoneId, sectionId);
        return formEditorService.readData(stoneId, sectionId)
            .then((response) => {
                console.log("LOADED FORM DATA LOCAL", response);
                if (response.data != null) {
                    return (response.data.data);
                }
                return null;
            })
            .catch((response) => {
                console.log("LOAD FORM DATA: ", response);
                return false;
            });
    }
    function loadFormData(stoneId, sectionId) {
        console.log("LOAD FORM DATA", stoneId, sectionId);
        return formEditorService.readData(stoneId, sectionId)
            .then((response) => {
                console.log("LOADED FORM DATA", response);
                if (response.data != null) {
                    assigntWidgetsData(response.data.data);
                    setWatchData();
                }
                wasModified.value = false; // probabilmente non serve, non ci sono watch sui dati
                return success('Il caricamento è andato a buon fine.')
            })
            .catch((response) => {
                console.log("LOAD FORM DATA: ", response);
                return failure('Si è verificato un errore durante il caricamento dei dati');
            });
    }

    function setWatchData() {

    }
    let unwatchDef = [];
    function setWatchDef() {
        console.log("SET WATCH DEF");
        if (unwatchDef) {
            console.log("UNWATCHING");
            unwatchDef.forEach((unwatch) => {
                unwatch();
            });
            unwatchDef = [];
        }
        console.log("REGISTER");
        Object.keys(formDefIndex.value).forEach((key) => {
            if (formDefIndex.value[key].instanceProperties.id != "0") {
                unwatchDef.push(watch(
                    // () => (formDefIndex.value[key].instanceProperties),
                    formDefIndex.value[key].instanceProperties,
                    (newValue, oldValue) => {
                        console.log("EXEC WATCH", key);
                        wasModified.value = true;
                        console.log("OLD ", oldValue);
                        console.log("NEW", newValue);
                    }, { deep: true }
                ));
            }
        });
        console.log("WATCH FOR LENGTH");
        unwatchDef.push(watch(
            () => Object.keys(formDefIndex.value).length,
            (newValue, oldValue) => {
                console.log("EXEC WATCH LENGTH");
                wasModified.value = true;
                console.log("OLD LENGTH", oldValue);
                console.log("NEW LENGTH", newValue);
            }, { deep: false }
        ));
        console.log("SET WATCH DEF END");
    }
    const validationErrors = reactive({
        stones: [],
        sectionsByStone: {},
        fieldsBySectionByStone: {}
    });

    function pushError(stoneId, sectionId, widgetId, error) {
        // stone
        if (!validationErrors.stones.includes(stoneId)) {
            validationErrors.stones.push(stoneId);
        }
        // section
        if (!_.has(validationErrors.sectionsByStone, stoneId)) {
            validationErrors.sectionsByStone[stoneId] = [];
        }
        if (!validationErrors.sectionsByStone[stoneId].includes(sectionId)) {
            validationErrors.sectionsByStone[stoneId].push(sectionId);
        }
        // field
        if (!_.has(validationErrors.fieldsBySectionByStone, stoneId)) {
            validationErrors.fieldsBySectionByStone[stoneId] = {};
        }
        if (!_.has(validationErrors.fieldsBySectionByStone[stoneId], sectionId)) {
            validationErrors.fieldsBySectionByStone[stoneId][sectionId] = {};
        }
        validationErrors.fieldsBySectionByStone[stoneId][sectionId][widgetId] = error;
        console.log("VAL ERROR: ", stoneId, sectionId, widgetId);
    }

    function validateFormData(key, _formDef, _formDefIndex, data) {
        console.log("VALIDATE");

        let valid = true;
        Object.keys(_formDefIndex).forEach((widgetId) => {
            if (widgetId == "0") {
                console.log("ROOT WIDGET");
            } else {
                const widget = _formDefIndex[widgetId];
                if (rulesService.isVisible(widget) &&
                    !rulesService.isDisabled(widget) &&
                    ((widget.validationProperties && widget.validationProperties.required) || rulesService.isRequired(widget)) &&
                    (
                        !data[widget.instanceProperties.name] ||
                        _.isEmpty(data[widget.instanceProperties.name])
                    )
                ) {
                    valid = false;
                    switch (widget.classProperties.name) {
                        case 'input-date':
                        case 'input-text':
                        case 'button-group':
                        case 'selectbox':
                        case 'image':
                        case 'file': {
                            if (!data || !data[widget.instanceProperties.name]) {
                                pushError(key.stone, key.section, widgetId, 'Il campo e obbligatorio');
                            }
                            break;
                        }
                        case 'checkbox-group': {
                            if (!data || !data[widget.instanceProperties.name]?.length) {
                                pushError(key.stone, key.section, widgetId, 'Il campo e obbligatorio');
                            }
                            break;
                        }
                        case 'range':
                        case 'input-number': {
                            if ((!data || data[widget.instanceProperties.name] == undefined || data[widget.instanceProperties.name] == null)) {
                                pushError(key.stone, key.section, widgetId, 'Il campo e obbligatorio');
                            }
                            break;
                        }
                    }
                }
            }
        });
        console.log("VALIDATE END", valid);
        return valid;
    }

    function validateAllFormData() {
        console.log("AZZERAMENTOERRORI");
        // validationErrors.value.stones = []; // []
        // validationErrors.value.sectionsByStone = {}; // {}[]
        // validationErrors.value.fieldsBySectionByStone = {}; // {}{}[]
        validationErrors.stones = []; // []
        validationErrors.sectionsByStone = {}; // {}[]
        validationErrors.fieldsBySectionByStone = {}; // {}{}[]
        console.log("AZZERAMENTOERRORI END");

        let dataLoadingPromises = [];

        processingStore.currentProcessing.lot.stones.forEach(async (stone) => {
            const stoneId = stone.id;
            stone.type_analysis.sections.forEach(async (section) => {
                const sectionId = section.id;
                const localFormDef = section.form_definitions_latest_version != null ? JSON.parse(section.form_definitions_latest_version.fields) : null;
                const localFormDefIndex = rebuildIndexLocal(localFormDef);
                if (Object.keys(localFormDefIndex).length) {
                    dataLoadingPromises.push(
                        loadFormDataLocal(stoneId, sectionId)
                            .then((response) => {
                                return {
                                    localFormDef,
                                    localFormDefIndex,
                                    stoneId,
                                    sectionId,
                                    data: response
                                }
                            })
                    );
                }
            });
        });

        return Promise.all(dataLoadingPromises)
            .then((dataLoadingResponses => {
                console.log("START VAL");
                const validatedResponses = dataLoadingResponses.map((response) => {
                    if (!validateFormData({ stone: response.stoneId, section: response.sectionId }, response.localFormDef, response.localFormDefIndex, response.data)) {
                        return false;
                    } else {
                        return true;
                    }
                })
                console.log("VALIDATION dataLoadingRESPONSES: ", dataLoadingResponses);
                return validatedResponses.every(response => response === true);
            }));
    }

    function getWidgetValueByName(subject) {
        const widget = _.find(formDefIndex.value, function (o) {
            return o.instanceProperties.name == subject;
        });
        if (widget) {
            return widget.runtimeProperties.data;
        }
    }

    function getWidgetValueTypeByName(subject) {
        const widget = _.find(formDefIndex.value, function (o) {
            return o.instanceProperties.name == subject;
        });
        if (widget) {
            if (widget.classProperties.valueType) {
                // V2
                return widget.classProperties.valueType;
            } else {
                // V1
                let response;
                switch (widget.classProperties.name) {
                    case 'container': {
                        response = 'none';
                        break;
                    }
                    case 'input-text': {
                        response = 'one';
                        break;
                    }
                    case 'input-number': {
                        response = 'one';
                        break;
                    }
                    case 'input-date': {
                        response = 'one';
                        break;
                    }
                    case 'checkbox-group': {
                        response = 'multi';
                        break;
                    }
                    case 'checkbox': {
                        response = 'bool';
                        break;
                    }
                    case 'radio-group': {
                        response = 'one';
                        break;
                    }
                    case 'button-group': {
                        response = 'one';
                        break;
                    }
                    case 'range': {
                        response = 'one';
                        break;
                    }
                    case 'selectbox': {
                        response = 'multi';
                        break;
                    }
                    case 'image': {
                        response = 'data';
                        break;
                    }
                    case 'file': {
                        response = 'data';
                        break;
                    }
                }
                return response;
            }
        }
    }


    initNomenclature();

    let cacheFormDef = ref({});

    function cacheSetCurrentForm(key) {
        cacheSetForm(key, formDef.value, formDefIndex.value);
    }
    function cacheSetForm(key, formDef, formDefIndex) {
        console.log("CACHE SET KEY", key);
        cacheFormDef.value[JSON.stringify(key)] = {
            formDef: formDef,
            formDefIndex: formDefIndex,
        };
        console.log("CACHE SET DATA", cacheFormDef.value[JSON.stringify(key)]);
    }
    function cacheGetCurrentForm(key) {
        console.log("CACHE GET KEY", key);
        // if (cacheHasForm(key)) {
        const hashedKey = JSON.stringify(key);
        formDef.value = cacheFormDef.value[hashedKey].formDef;
        formDefIndex.value = cacheFormDef.value[hashedKey].formDefIndex;
        console.log("CACHE GET DATA", cacheFormDef.value[hashedKey]);
        clearSelection();
        setWatchDef();
        // }
        return undefined;
    }
    function cacheHasForm(key) {
        console.log("CACHE HAS KEY (res)", key, !!cacheFormDef.value[JSON.stringify(key)]);
        return !!cacheFormDef.value[JSON.stringify(key)];
    }
    async function cacheFormDefinition(key, _sectionId) {
        const response = await formEditorService.load(_sectionId);
        console.log("CACHE FORM DEF: ", response);
        if (response.data.definition != null) {
            const formDef = response.data.definition.fields;
            // const definitionId = response.data.definition.id;
            const formDefIndex = rebuildIndexLocal(formDef);
            cacheSetForm(key, formDef, formDefIndex);
        }
        return success(null);
    }

    async function cacheFormData(key, stoneId, sectionId) {
        console.log("CACHE FORM DATA", stoneId, sectionId);
        const formData = await loadFormDataLocal(stoneId, sectionId);

        const hashedKey = JSON.stringify(key);
        const formDef = cacheFormDef.value[hashedKey].formDef;
        // formDefIndex.value = cacheFormDef.value[hashedKey].formDefIndex;

        recursiveAssignWidgetsData(formDef, formData)
    }
    async function cacheUpdateRuntimeData(key, widget) {
        console.log("CACHE UPDATE RUNTIME FORM DATA", key);

        const hashedKey = JSON.stringify(key);
        // const formDef = cacheFormDef.value[hashedKey].formDef;
        const formDefIndex = cacheFormDef.value[hashedKey].formDefIndex;

        Object.keys(formDefIndex).forEach((widgetDefId) => {
            if (formDefIndex[widgetDefId].instanceProperties.id == widget.instanceProperties.id) {
                formDefIndex[widgetDefId].runtimeProperties.data = widget.runtimeProperties.data;
            }
        });
    }



    return {
        // STATE
        formDef,
        formDefIndex,
        formDefSelectedWidgets,
        widgetCounters,
        isUniqueWidgetName,

        definitionId,

        // isModified,
        wasModified,

        suspendedNavDestination,
        showFormNotSavedModal,

        // METHODS
        componentFromWidgetClassName,
        widgetDefFromClassName,
        propertiesEditorFromWidgetId,

        addRootWidget,
        appendWidget,
        insertWidgetBefore,
        appendWidgetInline,
        insertWidgetInlineBefore,

        removeWidget,
        collectWidgetsData,

        widgetInstanceProps,
        widgetRuntimeProps,

        toggleSelection,
        clearSelection,
        isSelected,

        loadFormDefinition,
        saveFormDefinition,
        saveAllFormData,
        loadFormData,

        rebuildIndex,

        cacheFormDef,
        cacheHasForm,
        cacheGetCurrentForm,
        cacheSetCurrentForm,
        cacheFormDefinition,
        cacheFormData,
        cacheUpdateRuntimeData,

        getWidgetValueByName,
        getWidgetValueTypeByName,
        validateAllFormData,
        validationErrors
    };
});

