import { ref } from "vue";
import { defineStore } from "pinia";
import processingService from "../../../services/processing"

export const useProcessingStore = defineStore('processing-store', () => {
    const currentProcessing = ref();

    const sort = ref([]);
    const filters = ref([]);

    function createProcessing(model) {
        return processingService.create(model);
    }
    function details(id) {
        return processingService.details(id)
            .then((response) => {
                console.log("PROCESSING STONES READ", response.data.processing.lot.stones);
                currentProcessing.value = response.data.processing;

                if (currentProcessing.value.lot.stones && currentProcessing.value.lot.stones.length && currentProcessing.value.lot.stones[0].name != null) {
                    currentProcessing.value.lot.stones.sort((a, b) => a.name.localeCompare(b.name));
                } else {
                    currentProcessing.value.lot.stones.sort((a, b) => a.type_analysis.id.localeCompare(b.type_analysis.id));
                    currentProcessing.value.lot.stones.forEach((stone, index) => {
                        stone.name = currentProcessing.value.lot.name + "-" + (index + 1);
                    });
                }

                return currentProcessing.value;
            })
            .catch((response) => {
                console.error("PROCESSING STORE -> DETAILS: ", response);
                return false;
            });
    }
    function detailsProcessing(id) {
        return processingService.details(id);
    }
    async function fetchProcesses(filter, sort, pagination, format) {
        console.log('SORT', sort);
        const s = await processingService.search(
            filter.filter(item => item.value),
            sort.filter(item => item.dir),
            pagination,
            format
        );
        console.log("fetchProcesses: ", s);
        return s;
    }
    function deleteProcessing(id) {
        return processingService.delete(id);
    }
    async function fetchProcessingFromStone(stone_id) {
        const p = await processingService.detailsFromStone(stone_id);
        if (p) {
            return p.data.processing;
        }
        return false;
    }
    return {
        createProcessing,
        details,
        detailsProcessing,
        fetchProcesses,
        deleteProcessing,

        currentProcessing,
        fetchProcessingFromStone,

        sort,
        filters
    }

})
